/*** COLOR CODES 3 **/
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */



/**  Build Comment: including file /setup/__preheader.scss  **/ 
@use "sass:math";
@use "sass:color";

// Consider using https://www.purgecss.com/ to remove styles not used in the project.
/** SCSS DOC: _preheader.scss **/
$debug-trace: false !default;
$name-mode: 'SHORT' !default; // $name-mode: "SHORT","LONG","ALL"
$ms-base: 1rem !default; // needs to be in rem's
$colors: ();
$grays: ();
$theme-palette: ();
$usingFontAwesome:true;
@import 'helpers/helpers';
@import 'vendor/modular-scale';
/** SCSS DOC: _utility-css.vars.scss **/
@import 'setup/config/utility-css.vars';
@import 'helpers/breakpoint';
%font-awesome-pro {
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-family: 'Font Awesome 6 Pro', 'Font Awesome 5 Pro';
	font-weight: 900;
}




/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/ 

$active-class-name: 'active';


/**  Build Comment: Set Active Class  **/ 
/**  No Brands Are Used for small project that need to be included with a larger base style sheet.    *********************************/
/**  Colors  **/
/** SCSS DOC: __brand.none.scss **/
@import 'setup/config/colors';


/**  Build Comment: Start with No Branding 4.1.4x This is for an augmenting color sheets that don't require a brand  **/ 
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/

$margin_0: get-css-size(margin, 0);
$margin_1: get-css-size(margin, 1);
$margin_2: get-css-size(margin, 2);
$margin_3: get-css-size(margin, 3);
$margin_4: get-css-size(margin, 4);
$margin_5: get-css-size(margin, 5);
$margin_n1: get-css-size(margin, n1);
$margin_n2: get-css-size(margin, n2);
$margin_n3: get-css-size(margin, n3);
$margin_n4: get-css-size(margin, n4);
$margin_n5: get-css-size(margin, n5);
$padding_0: get-css-size(padding, 0);
$padding_1: get-css-size(padding, 1);
$padding_2: get-css-size(padding, 2);
$padding_3: get-css-size(padding, 3);
$padding_4: get-css-size(padding, 4);
$padding_5: get-css-size(padding, 5);
$border_square: 0;
$border_radius: get-css-size(border-radius, radius);
$border_round: get-css-size(border-radius, round);
$border_circle: get-css-size(border-radius, circle);
$border-width_0: get-css-size(border-width, 0);
$border-width_1: get-css-size(border-width, 1);
$border-width_2: get-css-size(border-width, 2);
$border-width_3: get-css-size(border-width, 3);
$border-width_4: get-css-size(border-width, 4);
$border-width_5: get-css-size(border-width, 5);
$font_display: var(--font-family_display);
$font_accent: var(--font-family_accent);
$font_ui: var(--font-family_ui);
$font_copy: var(--font-family_copy);
$font_mono: var(--font-family_mono);
$font_6: get-css-size(font-size, 6);
$font_5: get-css-size(font-size, 5);
$font_4: get-css-size(font-size, 4);
$font_3: get-css-size(font-size, 3);
$font_2: get-css-size(font-size, 2);
$font_1: get-css-size(font-size, 1);
$font_0: get-css-size(font-size, 0);
$font_n1: get-css-size(font-size, n1);
$font_n2: get-css-size(font-size, n2);
$font_n3: get-css-size(font-size, n3);
$font_n4: get-css-size(font-size, n4);
$font_n5: get-css-size(font-size, n5);
$font_bold: var(--font_bold);
$font_medium: var(--font_medium);
$font_xbold: var(--font_xbold);
$font_light: var(--font_light);
$font_regular: var(--font_regular);
$line-height_0: get-css-size(line-height, 0);
$line-height_1: get-css-size(line-height, 1);
$line-height_2: get-css-size(line-height, 2);
$line-height_3: get-css-size(line-height, 3);
$line-height_4: get-css-size(line-height, 4);
$line-height_5: get-css-size(line-height, 5);
/** SCSS DOC: _setup.none.scss **/

/**  Build Comment: LOE_COR Color Stylesheet  **/ 
@each $color-name, $color in $COR {
    $color-name-alt: 'cor-' + $color-name;
    .c_#{$color-name-alt} {
        color: $color !important;
    }
    .br_#{$color-name-alt} {
        border-color: $color !important;
    }
    .bg_#{$color-name-alt},
    .#{$color-name-alt} {
        background-color: $color !important;
    }
}

@each $color-name, $color in $LOE {
    $color-name-alt: 'loe-' + $color-name;
    .c_#{$color-name-alt} {
        color: $color !important;
    }
    .br_#{$color-name-alt} {
        border-color: $color !important;
    }
    .bg_#{$color-name-alt},
    .#{$color-name-alt} {
        background-color: $color !important;
    }
}
/** SCSS DOC: __globalshame_uc.scss **/