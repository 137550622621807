/*** COLOR CODES 3 **/
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */
/**  Build Comment: including file /setup/__preheader.scss  **/
/** SCSS DOC: _preheader.scss **/
/** SCSS DOC: _helpers.scss **/
/** SCSS DOC: _utility-css.vars.scss **/
/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/
/**  Build Comment: Set Active Class  **/
/**  No Brands Are Used for small project that need to be included with a larger base style sheet.    *********************************/
/**  Colors  **/
/** SCSS DOC: __brand.none.scss **/
/** SCSS DOC: _colors.scss **/
/** Setting link color variables in scss **/
/*

Link Colors: Light

Link colors of a lighter shade in all of its states, default, hover, visited. Typically used to enhance visibility on dark backgrounds.

$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:2

Styleguide DesignBase.Colors.LinksLight
 */
/*

Link Colors

Link colors  in all of its states, default, hover, visited.


$link-color-light			- #8cebda; Link Color light			
$link-color-light--hover	- #e8fbf8; Link Color light--hover	
$link-color-light--visited	- #84afa7; Link Color light--visited	
$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:1

Styleguide DesignBase.Colors.Links
 */
/**  Build Comment: Start with No Branding 4.1.4x This is for an augmenting color sheets that don't require a brand  **/
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/
/** SCSS DOC: _setup.none.scss **/
/**  Build Comment: LOE_COR Color Stylesheet  **/
.c_cor-I {
  color: #6fc284 !important;
}

.br_cor-I {
  border-color: #6fc284 !important;
}

.bg_cor-I,
.cor-I {
  background-color: #6fc284 !important;
}

.c_cor-IIA {
  color: #ffd44f !important;
}

.br_cor-IIA {
  border-color: #ffd44f !important;
}

.bg_cor-IIA,
.cor-IIA {
  background-color: #ffd44f !important;
}

.c_cor-IIB {
  color: #faa74b !important;
}

.br_cor-IIB {
  border-color: #faa74b !important;
}

.bg_cor-IIB,
.cor-IIB {
  background-color: #faa74b !important;
}

.c_cor-III {
  color: #f15d4c !important;
}

.br_cor-III {
  border-color: #f15d4c !important;
}

.bg_cor-III,
.cor-III {
  background-color: #f15d4c !important;
}

.c_cor-III-NOBENIFIT {
  color: #f15d4c !important;
}

.br_cor-III-NOBENIFIT {
  border-color: #f15d4c !important;
}

.bg_cor-III-NOBENIFIT,
.cor-III-NOBENIFIT {
  background-color: #f15d4c !important;
}

.c_cor-III-HARM {
  color: #ed1c00 !important;
}

.br_cor-III-HARM {
  border-color: #ed1c00 !important;
}

.bg_cor-III-HARM,
.cor-III-HARM {
  background-color: #ed1c00 !important;
}

.c_cor-III-4 {
  color: #fcdbd8 !important;
}

.br_cor-III-4 {
  border-color: #fcdbd8 !important;
}

.bg_cor-III-4,
.cor-III-4 {
  background-color: #fcdbd8 !important;
}

.c_loe-A {
  color: rgb(62, 111, 183) !important;
}

.br_loe-A {
  border-color: rgb(62, 111, 183) !important;
}

.bg_loe-A,
.loe-A {
  background-color: rgb(62, 111, 183) !important;
}

.c_loe-B-NR {
  color: rgb(101, 156, 211) !important;
}

.br_loe-B-NR {
  border-color: rgb(101, 156, 211) !important;
}

.bg_loe-B-NR,
.loe-B-NR {
  background-color: rgb(101, 156, 211) !important;
}

.c_loe-B-BR {
  color: rgb(161, 193, 230) !important;
}

.br_loe-B-BR {
  border-color: rgb(161, 193, 230) !important;
}

.bg_loe-B-BR,
.loe-B-BR {
  background-color: rgb(161, 193, 230) !important;
}

.c_loe-C-LD {
  color: rgb(181, 213, 229) !important;
}

.br_loe-C-LD {
  border-color: rgb(181, 213, 229) !important;
}

.bg_loe-C-LD,
.loe-C-LD {
  background-color: rgb(181, 213, 229) !important;
}

.c_loe-C-EO {
  color: rgb(181, 213, 229) !important;
}

.br_loe-C-EO {
  border-color: rgb(181, 213, 229) !important;
}

.bg_loe-C-EO,
.loe-C-EO {
  background-color: rgb(181, 213, 229) !important;
}

/** SCSS DOC: __globalshame_uc.scss **/